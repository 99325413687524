<template>
<v-card flat class="pa-3 mt-2">

    <v-card-text>
        <div id="app">

            <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ snackbartest.title }}</strong>
                        </div>
                        <div>{{ snackbartest.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>

            <v-data-table :headers="headers" :items="listodd" sort-by="id" class="elevation-1">
                <template v-slot:[`item.image`]="{ item }">
                    <div class="p-2">
                        <v-img :src="lienimage+item.image" :alt="item.libelle" height="50px" width="50"></v-img>
                    </div>
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>LISTE ODD</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                                    Nouveau
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-text-field v-model="code" label="CODE" dense outlined required></v-text-field>
                                            <v-text-field v-model="libelle" label="Désignation" dense outlined required></v-text-field>
                                            <v-file-input label="Selectionnez une image" outlined dense v-model="image"></v-file-input>
                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                        Actualiser la liste
                    </v-btn>
                </template>
            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import api from './../../../serviceApi/apiService'
export default {
    name: 'ODD',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'ODD'
    },
    components: {
        VuePerfectScrollbar
    },

    mounted() {
        this.readAll();
    },
    data: () => ({
        multiLine: true,
        snackbar: false,
        snackbars: false,
        snackbartest: false,
        text: `message`,
        textmessage: '',
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        headers: [{
                text: 'ID',
                value: 'id',
                sortable: false,
                divider: true,
                width: '30px'
            },
            {
                text: 'Code',
                value: 'code',
                divider: true,
                width: '30px'
            },
            {
                text: 'Libelle odd',
                align: 'start',

                value: 'libelle',
                divider: true,
            },
            {
                text: 'Image',
                value: 'image',
                divider: true,
                width: '100px'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '80px'
            },
        ],
        formTitle: 'Formulaire',
        desserts: [],
        lienimage:'https://masterapi.piebarometre.digital/storage/imagesodd/',
       // lienimage: 'http://127.0.0.1:8000/storage/imagesodd/',
        listodd: [], 
        libelle: '',
        valid: true,
        libelleRules: [
            v => !!v || 'Veuillez inserer la désignation',
        ],
        image: null,
        code: '',
        codeRules: [
            v => !!v || 'Veuillez inserer le code',
        ],
        id_odds: '0',
        editedIndex: -1,
        textetat: false,
        messages: '',
    }),
    methods: {
        readAll: async function () {
            const data = await api.readAll('odds/all');
            this.listodd = data.list;
        },

        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('odds/delete', fd);
            this.messagesligne(res);
        },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('image', this.image);
            fd.append('code', this.code);
            fd.append('libelle', this.libelle);

            if (this.textetat) {

                const res = await api.createUpdatedata('odds/update/' + this.id_odds, fd);
                if (res.status == 200) {

                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    //this.text=res.message;
                    //this.snackbar=true;
                }

            } else {
                const res = await api.createUpdatedata('odds/add', fd);
                // this.text=res.message;
                // this.snackbar=true;
                this.textmessage = res.message;
                this.snackbartest = {
                    color: "green",
                    icon: "mdi-check-all",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Information",
                    text: this.textmessage,
                    visible: true
                };
            }
            this.readAll();
            this.clear();

        },
        nouveau() {
            this.id_odds = "0";
            this.libelle = " ";
            this.code = " ";
            this.textetat = false;

            //this.dialog=false;
        },

        messagesligne(res) {

            this.timeout = 2000;

            if (res.status == 200) {
                this.textsnackbar = res.message;
                this.readAll();
            } else {
                this.textsnackbar = res.message;
            }
        },

        clear() {
            this.image = null;
            this.id_odds = "0";
            this.libelle = "";
            this.code = "";
            this.textetat = false;
            // this.dialog=false;
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id_odds = item.id;
            this.libelle = item.libelle;
            this.code = item.code;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false
        },

        closeDelete() {
            this.dialogDelete = false
        },

        close() {
            this.dialog = false
        },

        save() {
            if (this.libelle == '' || this.code == '') {

                this.messages = "Veuillezsss remplir les champs";
                //this.Notifications(this.messages);
                this.text = "Veuillez remplir les champs";
                this.snackbar = true;
                return;
            }

            this.createUpdatedata();
            this.readAll();
            if (this.textetat) {
                this.dialog = false;
            }
        },
        appToggle: function () {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        },
        overlayApp: function () {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        }
    }
}
</script>

<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;

        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }

    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;

        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;

            &.open {
                left: 0;
            }
        }

        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);

        @media only screen and (max-width: 959px) {
            width: 100%;
        }

        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }

        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}

.app-icon {
    display: none;

    @media only screen and (max-width: 959px) {
        display: block;
    }
}

.eg-filemanager {
    background-color: #fff;
}

.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
